/* eslint-disable no-console */
import * as qs from 'qs';
import { PathLike } from 'fs';
import axios from 'axios';

const baseURL =
  process.env.NEXT_PUBLIC_API_BASE_URL || 'https://api.lemme.app/api';

export const axiosLoggedInConfig = {
  baseURL,
  withCredentials: true,
  timeout: 35000,
  headers: {
    common: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  },
  paramsSerializer: (params: PathLike) =>
    qs.stringify(params, { indices: false }),
};

// Axios TS issue: https://github.com/axios/axios/issues/4193#issuecomment-1097139357
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const httpClient = axios.create(axiosLoggedInConfig);

export default httpClient;
