import { Button } from '@mui/material';
import { ButtonProps, CircularProgressProps } from '@mui/material';
import { Children } from 'src/@types';
import CircularProgress from '../CircularProgress/CircularProgress';

export interface CircularProgressButtonProps extends ButtonProps {
  circularProgressProps?: CircularProgressProps;
  forceHideSpinner?: boolean;
  children: Children;
}

const CircularProgressButton = ({
  children,
  forceHideSpinner = false,
  circularProgressProps = {},
  ...buttonProperties
}: CircularProgressButtonProps) => {
  const { disabled } = buttonProperties;
  const renderSpinner = Boolean(disabled) || forceHideSpinner;
  return (
    <Button
      variant="contained"
      color="primary"
      type="button"
      {...buttonProperties}
    >
      {renderSpinner && (
        <CircularProgress size={30} thickness={2} {...circularProgressProps} />
      )}
      {!renderSpinner && children}
    </Button>
  );
};

export default CircularProgressButton;
