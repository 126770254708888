import Launching from 'src/types/Launching';

export const HOME = '/';
export const DASHBOARD = '/dashboard';
export const LOGIN = '/login';
export const REGISTER = '/register';
export const LOBBY = '/lobby';
export const LAUNCHINGS = '/launchings';
export const LAUNCHING = {
  route: (launching: Launching): string => `${LAUNCHINGS}/${launching.slug}`,
  path: 'LAUNCHING/{{slug}}',
};
export const ACCOUNT = {
  route: (accountUuid: string): string => `/account/${accountUuid}`,
  path: 'ACCOUNT/{{accountUuid}}',
};
export const FORGOT_PASSWORD = '/forgot-password';
export const TERMS_OF_USE = '/terms-of-use';
export const PRIVACY_POLICY = '/privacy-policy';
