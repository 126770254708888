import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import MuiTextField, {
  TextFieldProps as MuiTextFieldProps,
} from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import { IconButton, InputAdornment } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import createStyles from '@mui/styles/createStyles';
import { Theme } from '@mui/system/createTheme';
import { makeStyles } from '@mui/styles';
import { sanitizeFieldValue } from 'src/utils/formHelpers';

enum TextfieldType {
  PASSWORD = 'password',
  TEXT = 'text',
}

const useStyles = makeStyles(({ palette }: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    inputAdornment: {
      color: palette.grey[600],
    },
    inputAdornmentIcon: {
      fontSize: '1.18rem',
      color: palette.grey[300],
    },
  }),
);

interface PasswordInputAdornmentProps {
  onClick(param: unknown): void;
  showPassword?: boolean;
}
const PasswordInputAdornment = ({
  onClick,
  showPassword = false,
}: PasswordInputAdornmentProps) => {
  const styles = useStyles();
  return (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={onClick}
        edge="end"
        className={styles.inputAdornment}
        disableRipple={true}
        size="large"
      >
        {showPassword ? (
          <Visibility className={styles.inputAdornmentIcon} />
        ) : (
          <VisibilityOff className={styles.inputAdornmentIcon} />
        )}
      </IconButton>
    </InputAdornment>
  );
};

interface TextFieldPropInterface<T extends FieldValues> {
  TextFieldProps?: MuiTextFieldProps;
  name: Path<T>;
  control: Control<T>;
  label?: string;
  type?: string;
  formatter?: (value: string) => string;
}
const TextField = <T extends FieldValues>({
  control,
  name,
  label = '',
  type,
  formatter = undefined,
  ...TextFieldProps
}: TextFieldPropInterface<T> & MuiTextFieldProps): JSX.Element => {
  const [showPassword, toggleShowPassword] = useState<boolean>(
    type !== TextfieldType.PASSWORD,
  );
  const { t } = useTranslation();

  const handleClickShowPassword = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      toggleShowPassword(!showPassword);
    },
    [showPassword],
  );

  const currentType = useMemo(() => {
    let result = showPassword ? type : TextfieldType.PASSWORD;

    // If the prop.type is 'password' but it wants to force show the password...
    if (showPassword && type === TextfieldType.PASSWORD) {
      result = TextfieldType.TEXT;
    }

    return result;
  }, [showPassword, type]);

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { error },
      }) => {
        return (
          <div>
            <InputLabel
              error={!!error}
              color="primary"
              id={`label-for-${label}`}
            >
              {label}
            </InputLabel>
            <MuiTextField
              {...TextFieldProps}
              value={sanitizeFieldValue(value)}
              onChange={
                formatter
                  ? ({ target: { value } }) => onChange(formatter(value))
                  : onChange
              }
              onBlur={onBlur}
              error={!!error}
              helperText={t(error?.message || '')}
              InputProps={{
                endAdornment:
                  type === 'password' ? (
                    <PasswordInputAdornment
                      onClick={handleClickShowPassword}
                      showPassword={showPassword}
                    />
                  ) : null,
              }}
              type={currentType}
              name={name}
            />
          </div>
        );
      }}
    />
  );
};

export default TextField;
