/* eslint-disable no-console */
import { AxiosResponse } from 'axios';
import httpClient from 'src/http/httpClient';
import {
  ForgotPasswordFormData,
  LoginFormData,
  LoginRequestResultData,
  RecoverPasswordFormData,
  RecoverPasswordResultData,
  RegisterFormData,
} from 'src/types/Login';
import User from 'src/types/User';

export async function me(): Promise<User> {
  try {
    const { data } = await httpClient.get('/user');
    return Promise.resolve(data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.error('call HTTP me() ERROR', error.config);
    return Promise.reject(error);
  }
}

export async function login(
  data: LoginFormData,
): Promise<AxiosResponse<LoginRequestResultData>> {
  try {
    const response = await httpClient.post('/login', data);
    return Promise.resolve(response);
  } catch (error) {
    console.log('call HTTP Login() ERROR: ', error);
    return Promise.reject(error);
  }
}

export async function forgotPassword(
  payload: ForgotPasswordFormData,
): Promise<AxiosResponse<ForgotPasswordFormData>> {
  return httpClient.post('/forgot-password', payload);
}

export async function recoverPassword(
  payload: RecoverPasswordFormData,
): Promise<AxiosResponse<RecoverPasswordResultData>> {
  return httpClient.post('/recover-password', payload);
}

export async function register(
  payload: RegisterFormData,
): Promise<AxiosResponse<RegisterFormData>> {
  return httpClient.post('/user', payload);
}

export async function logout(): Promise<void> {
  return httpClient.post('/logout');
}
