import User from './User';

export const API_TOKEN_NAME = 'token';
export const QUERY_LOGIN_KEY = ['user'];
export interface LoginFormData {
  email: string;
  password: string;
}

export enum DocumentType {
  CPF = 'CPF',
  PASSPORT = 'PASSPORT',
}

export interface LoginRequestResultData {
  success: boolean;
  profile?: string | null;
}

export interface ForgotPasswordFormData {
  email: string;
}

export interface ForgotPasswordResultData {
  email: string;
}
export interface JWTInformation {
  token: string;
  user_id: string;
  name: string;
  email: string;
  exp: number;
  orig_iat: number;
}

export interface RegisterFormData {
  name: string;
  email: string;
  date_of_birth: Date | string;
  document_type: DocumentType | '';
  document_number: string;
  terms_of_use: boolean;
  password: string;
  password_confirmation: string;
}

export interface RegisterRequestResultData {
  token: string;
  user?: User;
}

export interface RecoverPasswordFormData {
  email: string;
  password: string;
  password_confirmation: string;
  token: string;
}

export interface RecoverPasswordResultData {
  token: string;
}
