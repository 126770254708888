import theme from 'src/theme/theme';

export const NOTIFICATION_AUTOHIDE_DELAY = 5000;
export const FOOTER_HEIGHT = 72;
export const DEFAULT_CONTENT_MARGIN_TOP = theme.spacing(8);
export const MIN_BODY_HEIGHT_RULE = 600;
export const CARD_BUTTON_ICON_BOX_SIZE = 34;
export const DEFAULT_LAUNCHING_DURATION_IN_DAYS = 35;

export const TASK_STATUS_REFRESH_INTERVAL = 1 * 60 * 1000; // 1 minute in ms
export const FUTURE_AHEAD_TASK_DELIVERY_DATE = 48 * 60 * 60 * 1000; // x hours in ms
