import { NextRouter } from 'next/dist/client/router';
import i18n from 'src/core/i18n';
import { HOME } from 'src/routes/Routes';
import { DocumentType } from 'src/types/Login';
import { isOnBrowser } from 'src/utils/browser';
import { object, ref, string, boolean } from 'yup';
import { differenceInYears, parse } from 'date-fns';

export const passwordRegex =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&-_])[A-Za-z\d@$!%*#?&-_]{8,}$/;
export const phoneRegex = /^\d{8,9}$/;
export const areaCodeRegex = /^\d{2,2}$/;
export const dateRegex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
export const CPFRegex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;

const requiredMessage = i18n.t('validations:global.required');

const UserBusinessManager = {
  loginSchema: object().shape({
    email: string().email().required(requiredMessage),
    password: string()
      .matches(passwordRegex, i18n.t('validations:login.password_shape'))
      .required(requiredMessage),
  }),
  forgotPasswordSchema: object().shape({
    email: string().email().required(requiredMessage),
  }),
  recoverPasswordSchema: object().shape({
    email: string().email().required(requiredMessage),
    password: string()
      .matches(passwordRegex, i18n.t('validations:register.password_shape'))
      .required(requiredMessage),
    password_confirmation: string()
      .required(requiredMessage)
      .oneOf(
        [ref('password')],
        i18n.t('validations:register.password_doesnt_match'),
      ),
  }),
  registerSchema: object().shape({
    name: string().required(requiredMessage).min(2),
    email: string().email().required(requiredMessage),
    //TODO: create a new reusable rule for this scenario (like `string().required().isAdult()`)
    date_of_birth: string()
      .required(requiredMessage)
      .test(
        'registerSchema',
        i18n.t('validations:register.invalid_date'),
        (value, context) => {
          if (!(value && dateRegex.test(value))) {
            return false;
          }

          try {
            const date = parse(value, 'dd/MM/yyyy', new Date());
            if (!date || date.toString() === 'Invalid Date') {
              return false;
            }

            const age = differenceInYears(new Date(), date);
            // User age is between 18 and 98 years old:
            if (age < 18) {
              return context.createError({
                message: i18n.t('validations:register.underage'),
              });
            }
            if (age > 98) {
              return context.createError({
                message: i18n.t('validations:register.overage', { max: 90 }),
              });
            }

            return true;
          } catch (_) {
            return false;
          }
        },
      ),
    document_type: string()
      .required(requiredMessage)
      .oneOf([DocumentType.CPF, DocumentType.PASSPORT]),
    document_number: string()
      .required(requiredMessage)
      .when('document_type', {
        is: DocumentType.CPF,
        then: string().matches(
          CPFRegex,
          i18n.t('validations:global.invalidCPF'),
        ),
        otherwise: string()
          .min(3, i18n.t('validations:global.minChars', { min: 3 }))
          .max(30, i18n.t('validations:global.maxChars', { max: 30 })),
      }),
    terms_of_use: boolean()
      .required(requiredMessage)
      .equals([true], requiredMessage),
    password: string()
      .matches(passwordRegex, i18n.t('validations:register.password_shape'))
      .required(requiredMessage),
    password_confirmation: string()
      .required(requiredMessage)
      .oneOf(
        [ref('password')],
        i18n.t('validations:register.password_doesnt_match'),
      ),
  }),
  shouldRedirectAfterLogin(router: NextRouter): string | false {
    if (!isOnBrowser()) {
      return false;
    }
    const redirect = router.query.redirect;
    if (
      Boolean(redirect) &&
      redirect?.indexOf('login') === -1 &&
      redirect?.indexOf('lobby') === -1
    ) {
      return decodeURIComponent(redirect as string);
    }

    return HOME;
  },
};

export default UserBusinessManager;
