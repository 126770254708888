import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  CircularProgress as MuiCircularProgress,
  CircularProgressProps,
} from '@mui/material';

const useStyles = makeStyles(({ palette }: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
    },
    bgCircle: {
      color: palette.text.disabled,
      opacity: 0.4,
      display: 'block',
    },
    topCircle: {
      animationDuration: '800ms',
      position: 'absolute',
      display: 'block',
    },
    circle: {
      strokeLinecap: 'round',
    },
  }),
);

const CircularProgress = (props: CircularProgressProps) => {
  const styles = useStyles();
  const defaultProps: CircularProgressProps = {
    size: 30,
    thickness: 3,
    variant: 'indeterminate',
    ...props,
  };

  return (
    <div
      className={styles.root}
      style={{
        width: defaultProps.size,
        height: defaultProps.size,
      }}
    >
      <MuiCircularProgress
        {...defaultProps}
        variant="determinate"
        className={styles.bgCircle}
        value={100}
      />
      <MuiCircularProgress
        className={styles.topCircle}
        classes={{
          circle: styles.circle,
        }}
        {...defaultProps}
      />
    </div>
  );
};

export default CircularProgress;
